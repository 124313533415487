import { useEffect, useState } from "react";
import thumbnail from "../offline-thumbnail.png"

const Note = () => {
  return (
    <>
      <p className="note">
        The live stream and Feed Chickens button are only available when the
        WiFi at the farm is on, which is usually only when the farmers are
        working. In order to not spoil the chickens' diet, the feed button is
        only active once every 50 minutes and dispenses a small amount of meal
        worms. When someone presses it, it is deactivated again. There is a ~5s
        delay. Make sure you are running the highest possible quality on the
        YouTube live stream!
      </p>
    </>
  );
};

const FeedChickensBtn = ({ jsonData }) => {
  function progressBar() {
    const btn = document.querySelector(".feed-btn");
    if (jsonData?.currentTime - jsonData?.lastFeed > 50 * 60) {
      const elem = document.getElementById("myBar");
      let width = 1;
      const id = setInterval(frame, 25);
      function frame() {
        if (width >= 100) {
          clearInterval(id);
        } else {
          width++;
          elem.style.width = width + "%";
        }
      }
      const activateServo = async () => {
        try {
          const response = await fetch(
            "https://modest-distinctly-impala.ngrok-free.app/test",
            {
              method: "GET",
              mode: "cors",
              headers: {
                "ngrok-skip-browser-warning": "620",
              },
            }
          );
          const data = await response.json(); // assuming server responds with json
          console.log(data);
        } catch (error) {
          console.error("Fetch error:", error);
        }
      };
      activateServo();
      btn.classList.add("disabled");
    }
  }
  return (
    <>
      <button
        className={`${!(jsonData && jsonData.currentTime) && "invisible"} ${
          jsonData?.currentTime - jsonData?.lastFeed < 50 * 60 && "disabled"
        } feed-btn`}
        onClick={progressBar}
      >
        Feed Chickens!
      </button>
      <div id="myBar"></div>
    </>
  );
};

const Video = ({ jsonData }) => {
  if (jsonData) {
    return (
      <>
        <iframe
          src="https://www.youtube-nocookie.com/embed/live_stream?channel=UC0Iz91Ro75ozPkvI2LHnBgw"
          title="YouTube video player"
          frameborder=""
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share; fullscreen"
          allowfullscreen
        ></iframe>
      </>
    );
  } else {
    return (
      <>
      <img src={thumbnail} alt="Offline" className="offline-thumbnail"></img>
      </>
    )
  }
};

const MainContent = () => {
  const [jsonData, setJsonData] = useState("");
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          "https://modest-distinctly-impala.ngrok-free.app/",
          {
            method: "GET",
            mode: "cors",
            headers: {
              "ngrok-skip-browser-warning": "69420",
            },
          }
        );
        const data = await response.json();
        console.log(data);
        setJsonData(data);
      } catch (error) {
        console.error("Fetch error:", error);
      }
    };
    fetchData();
  }, []);
  return (
    <>
      <main>
        <Video jsonData={jsonData} />
        <FeedChickensBtn jsonData={jsonData} />
        <Note />
      </main>
    </>
  );
};

export { MainContent };
