const Footer = () => {
    return (
        <>
        <footer>
            Created by Summer '23 college intern <a href="mailto: ntripet16@gmail.com">Nathan Tripet</a>
        </footer>
        </>
    )
}

export { Footer }