const Header = () => {
  return (
    <>
      <header>
        <h1>Grow It Green Chicken Stream</h1>
        
      </header>
    </>
  );
};

export { Header };
